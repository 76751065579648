import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

import styles from './check-icon.module.scss';

const CheckIcon = () => (
    <FontAwesomeIcon
        className={styles.checkIcon}
        icon={faCheck}
    />
);

export default CheckIcon;
