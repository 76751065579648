/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition*/
/* eslint-disable react/jsx-pascal-case*/
/* eslint-disable react/jsx-fragments*/
/* eslint-disable quotes*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { Layout } from '../components/common';
import SEO from '../components/common/SEO';
import { useOpenContactModal, useViewport } from '../hooks';
import redesignStyles from './styles/techRedesign.module.scss';
import ogimage from '../images/technology/2023/products-hero-2.png';
import witboxFamilyMobile from '../images/technology/2023/witbox-family-banner-mobile.jpg';
import witboxOne from '../images/technology/2024/witbox-one-thumb.svg';
import witboxOneMobile from '../images/technology/2024/witbox-one-thumb-mobile.svg';
import witboxPlus from '../images/technology/2024/witbox-plus-thumb.svg';
import witboxPlusMobile from '../images/technology/2024/witbox-plus-thumb-mobile.svg';
import witboxNet from '../images/technology/2024/witbox-net-thumb.svg';
import witboxNetMobile from '../images/technology/2024/witbox-net-thumb-mobile.svg';
import scriptWriter from '../images/technology/2024/smart-navigate-new-features.png';
import smartgateDash from '../images/technology/2024/smartgate-new-features.svg';
import rda from '../images/technology/2024/rda-new-features.png';
import techHero from './styles/techHero.module.scss';
import hero from '../images/technology/2024/witbox-family-banner.jpg';
import rec from '../images/technology/2024/rec-product-card-thumb.png';
import recMobile from '../images/technology/2024/rec-product-card-thumb-mobile.jpg';
import workbench from '../images/technology/2024/workbench-product-card-thumb.png';
import workbenchMobile from '../images/technology/2024/workbench-product-card-thumb-mobile.jpg';
import smartgate from '../images/technology/2024/smartgate-product-card-thumb.png';
import smartgateMobile from '../images/technology/2024/smartgate-product-card-thumb-mobile.jpg';
import arrows from '../images/technology/2024/laptop-arrows.svg';
import NocSection from '../components/technology/NocSection';
import { GTM_KEYS, MODAL_SOURCE_BUTTONS } from '../constants';

const {
    TECHNOLOGY: {
        BOOK_DEMO,
        SUPPORTED_DEVICES,
        WITBOX_FAMILY,
        WITBOX_IN_ACTION,
    }
} = GTM_KEYS;
const { BOOK_DEMO_TECHNOLOGY } = MODAL_SOURCE_BUTTONS;

export default function Technology() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;
    const breakpointTablets = 821;
    const breakpointSSL = 1501;

    const openContactModal = useOpenContactModal(BOOK_DEMO_TECHNOLOGY);

    return (
        <Layout title='Technology - Witbe'>
            <SEO
                title='Technology - Witbe'
                ogDescription="Witbe's robots can automatically and proactively replicate any user behavior. The most comprehensive tool for monitoring and testing with a unique non-intrusive method"
                description="Witbe's robots can automatically and proactively replicate any user behavior. The most comprehensive tool for monitoring and testing with a unique non-intrusive method"
                image={ogimage}
            />
            <h1 className={redesignStyles.hidden}>Technology</h1>
            {width < breakpoint
                ? (
                    <div id={redesignStyles.mainImageWrapper}>
                        <div className={redesignStyles.mainImageTextWrapper}>
                            <div className={redesignStyles.mainImageLeft}>
                                <div className={redesignStyles.mainImageContent}>
                                    <h2 className={redesignStyles.mainImageTitle}>
                                        {t('siteMetadata.technology2024.mainH1')}
                                    </h2>
                                    <p className={redesignStyles.mainImageSub}>{t('siteMetadata.technology2024.description')}</p>
                                    <div className={redesignStyles.learnMoreBuyButtons}>
                                        <button
                                            className={`${redesignStyles.blueButtonMobile} ${redesignStyles.buyButton}`}
                                            onClick={openContactModal}
                                            type='button'
                                            data-gtm-id={BOOK_DEMO}
                                        >
                                            {t('siteMetadata.technology2024.demo')}
                                        </button>
                                        <Link
                                            to='/technology/supported-devices/'
                                            className={redesignStyles.cardLearnMoreInline}
                                            data-gtm-id={SUPPORTED_DEVICES}
                                        >
                                            {t('siteMetadata.technology2024.devices')}
                                        </Link>
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className={redesignStyles.chevronRight}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div id={techHero.mainImageWrapper}>
                        <div className={techHero.mainImageTextWrapper}>
                            <div className={techHero.mainImageContent}>
                                <h2 className={techHero.mainImageTitle}>
                                    {t('siteMetadata.technology2024.mainH1')}
                                </h2>
                                <p className={techHero.mainImageSub}>{t('siteMetadata.technology2024.description')}</p>
                                <div className={techHero.learnMoreBuyButtons}>
                                    <button
                                        className={techHero.blueButton}
                                        onClick={openContactModal}
                                        type='button'
                                        data-gtm-id={BOOK_DEMO}
                                    >
                                        {t('siteMetadata.technology2024.demo')}
                                    </button>
                                    <div className={techHero.cardLearnMoreInlineContainer}>
                                        <Link
                                            to='/technology/supported-devices/'
                                            className={techHero.cardLearnMoreInlineTablets}
                                            data-gtm-id={SUPPORTED_DEVICES}
                                        >
                                            {t('siteMetadata.technology2024.devices')}
                                        </Link>
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className={techHero.chevronRight}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            <div id={redesignStyles.secondImageWrapper}>
                <div id='witboxfam'>
                    {width < breakpoint
                        ? (
                            <div className={redesignStyles.secondImageTextWrapper}>
                                <div className={redesignStyles.secondImageLeft}>
                                    <div className={redesignStyles.secondImageContent}>
                                        <h3 className={redesignStyles.secondImageTitle}>
                                            {t('siteMetadata.products.secondaryHeroTitle')}
                                        </h3>
                                        <p className={redesignStyles.secondImageSub}>{t('siteMetadata.technology2024.compactSub')}</p>
                                        <div className={redesignStyles.learnMoreBuyButtons}>
                                            <Link
                                                to='/products/witbox/'
                                                className={redesignStyles.blueButton}
                                                data-gtm-id={WITBOX_FAMILY}
                                            >
                                                {t('siteMetadata.products.seeLineup')}
                                            </Link>
                                            <Link
                                                to='/articles/meet-the-witbox/'
                                                className={redesignStyles.cardLearnMoreInline}
                                                data-gtm-id={WITBOX_IN_ACTION}
                                            >
                                                {t('siteMetadata.products.witboxAction')}
                                            </Link>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={redesignStyles.chevronRight}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <img src={witboxFamilyMobile} id={redesignStyles.mainImg} alt='The Witbox Family' />
                            </div>
                        )
                        : (
                            <div className={redesignStyles.secondImageTextWrapper}>
                                <img src={hero} id={redesignStyles.mainImg} alt='Banner' />
                                <div className={techHero.carouselItemWrapper}>
                                    <div className={techHero.carouselItemText}>
                                        <h1 className={techHero.carouselItemTitle}>{t('siteMetadata.technology2024.compact')}</h1>
                                        <p className={techHero.carouselItemSub}>{t('siteMetadata.technology2024.compactSub')}</p>
                                        <div className={redesignStyles.learnMoreBuyButtons}>
                                            <Link
                                                to='/products/witbox/'
                                                className={redesignStyles.blueButton}
                                                data-gtm-id={WITBOX_FAMILY}
                                            >
                                                {width < breakpointSSL ? t('siteMetadata.technology2024.meetSSL') : t('siteMetadata.technology2024.meet')}
                                            </Link>
                                            <div className={redesignStyles.cardLearnMoreInlineContainer}>
                                                <Link
                                                    to='/articles/meet-the-witbox/'
                                                    className={redesignStyles.cardLearnMoreInline}
                                                    data-gtm-id={WITBOX_IN_ACTION}
                                                >
                                                    {t('siteMetadata.technology2024.see')}
                                                </Link>
                                                <FontAwesomeIcon
                                                    icon={faChevronRight}
                                                    className={redesignStyles.chevronRight}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
            {width < breakpointTablets
                ? (
                    <section id={redesignStyles.threeColSectionMobile}>
                        <ul id={redesignStyles.threeColListMobile}>
                            <Link
                                to='/products/witbox/'
                            >
                                <li className={redesignStyles.itemMobile}>
                                    <LazyLoad offset={100} className={redesignStyles.itemThumbMobile}>
                                        <img src={witboxOneMobile} className={redesignStyles.itemIconMobile} alt='Witbox One' />
                                    </LazyLoad>
                                    <div className={redesignStyles.itemTextMobile}>
                                        <h3 className={redesignStyles.itemTitleMobile} dangerouslySetInnerHTML={{ __html: t('siteMetadata.products.witboxOne') }} />
                                        <p className={redesignStyles.itemParagraphMobile} dangerouslySetInnerHTML={{ __html: t('siteMetadata.products.witboxOneSub') }} />
                                        <p className={redesignStyles.itemLearnMoreMobile}>
                                            {t('siteMetadata.products.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={redesignStyles.chevronRight}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link
                                to='/products/witbox/'
                            >
                                <li className={redesignStyles.itemMobile}>
                                    <LazyLoad offset={100} className={redesignStyles.itemThumbMobile}>
                                        <img src={witboxPlusMobile} className={redesignStyles.itemIconMobile} alt='Witbox Plus' />
                                    </LazyLoad>
                                    <div className={redesignStyles.itemTextMobile}>
                                        <h3 className={redesignStyles.itemTitleMobile} dangerouslySetInnerHTML={{ __html: t('siteMetadata.products.witboxPlus') }} />
                                        <p className={redesignStyles.itemParagraphMobile} dangerouslySetInnerHTML={{ __html: t('siteMetadata.products.witboxPlusSub') }} />
                                        <p className={redesignStyles.itemLearnMoreMobile}>
                                            {t('siteMetadata.products.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={redesignStyles.chevronRight}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link
                                to='/products/witbox/'
                            >
                                <li className={redesignStyles.itemMobile}>
                                    <LazyLoad offset={100} className={redesignStyles.itemThumbMobile}>
                                        <img src={witboxNetMobile} className={redesignStyles.itemIconMobile} alt='Witbox Net' />
                                    </LazyLoad>
                                    <div className={redesignStyles.itemTextMobile}>
                                        <h3 className={redesignStyles.itemTitleMobile} dangerouslySetInnerHTML={{ __html: t('siteMetadata.products.witboxNet') }} />
                                        <p className={redesignStyles.itemParagraphMobile} dangerouslySetInnerHTML={{ __html: t('siteMetadata.products.witboxNetSub') }} />
                                        <p className={redesignStyles.itemLearnMoreMobile}>
                                            {t('siteMetadata.products.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={redesignStyles.chevronRight}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                        </ul>
                    </section>
                )
                : (
                    <section id={redesignStyles.threeColSection}>
                        <ul id={redesignStyles.threeColList}>
                            <Link to='/products/witbox/'>
                                <li className={redesignStyles.item}>
                                    <LazyLoad offset={400} className={redesignStyles.itemThumb}>
                                        <img src={witboxOne} className={redesignStyles.itemIcon} alt='Witbox One' />
                                    </LazyLoad>
                                    <div className={redesignStyles.itemText}>
                                        <h3 className={redesignStyles.itemTitle} dangerouslySetInnerHTML={{ __html: t('siteMetadata.products.witboxOne') }} />
                                        <p className={redesignStyles.itemParagraph} dangerouslySetInnerHTML={{ __html: t('siteMetadata.products.witboxOneSub') }} />
                                        <p className={redesignStyles.itemLearnMore}>
                                            {t('siteMetadata.products.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={redesignStyles.chevronRight}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link to='/products/witbox/'>
                                <li className={redesignStyles.item}>
                                    <LazyLoad offset={400} className={redesignStyles.itemThumb}>
                                        <img src={witboxPlus} className={redesignStyles.itemIcon} alt='Witbox Plus' />
                                    </LazyLoad>
                                    <div className={redesignStyles.itemText}>
                                        <h3 className={redesignStyles.itemTitle} dangerouslySetInnerHTML={{ __html: t('siteMetadata.products.witboxPlus') }} />
                                        <p className={redesignStyles.itemParagraph} dangerouslySetInnerHTML={{ __html: t('siteMetadata.products.witboxPlusSub') }} />
                                        <p className={redesignStyles.itemLearnMore}>
                                            {t('siteMetadata.products.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={redesignStyles.chevronRight}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link to='/products/witbox/'>
                                <li className={redesignStyles.item}>
                                    <LazyLoad offset={400} className={redesignStyles.itemThumb}>
                                        <img src={witboxNet} className={redesignStyles.itemIcon} alt='Witbox Net' />
                                    </LazyLoad>
                                    <div className={redesignStyles.itemText}>
                                        <h3 className={redesignStyles.itemTitle} dangerouslySetInnerHTML={{ __html: t('siteMetadata.products.witboxNet') }} />
                                        <p className={redesignStyles.itemParagraph} dangerouslySetInnerHTML={{ __html: t('siteMetadata.products.witboxNetSub') }} />
                                        <p className={redesignStyles.itemLearnMore}>
                                            {t('siteMetadata.products.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={redesignStyles.chevronRight}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                        </ul>
                    </section>
                )}
            {width < breakpointTablets
                ? (
                    <div id={techHero.blueImageWrapper}>
                        <div className={techHero.mainImageTextWrapper}>
                            <div className={techHero.blueImageContent}>
                                <h1 className={techHero.mainImageTitleBlue}>
                                    {t('siteMetadata.technology2023.blueMobile')}
                                </h1>
                                <p className={techHero.mainImageSub}>{t('siteMetadata.technology2023.blueMobileSub')}</p>
                                <div className={techHero.learnMoreBuyButtons}>
                                    <div className={techHero.cardLearnMoreInlineContainerBlue}>
                                        <Link
                                            to='/articles/'
                                            className={techHero.cardLearnMoreInlineTabletsBlue}
                                        >
                                            {t('siteMetadata.technology2023.blueMobileButton')}
                                        </Link>
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className={techHero.chevronRightWhite}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                : null}
            <div id='suite'>
                {width < breakpoint
                    ? (
                        <>
                            <div id={redesignStyles.mainImageWrapperSuite}>
                                <div className={redesignStyles.mainImageTextWrapper}>
                                    <div className={redesignStyles.mainImageLeft}>
                                        <div className={redesignStyles.mainImageContent}>
                                            <h1 className={redesignStyles.mainImageTitle}>
                                                {t('siteMetadata.products.witbeSuite')}
                                            </h1>
                                            <p className={redesignStyles.mainImageSub}>{t('siteMetadata.technology2024.simplerSub')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul id={techHero.suiteListMobile}>
                                <li id={techHero.secondImageWrapper}>
                                    <div className={techHero.mainItemMobile}>
                                        <div className={techHero.mainCardImageWrapperMobile}>
                                            <img src={recMobile} alt='Remote Eye Controller' />
                                        </div>
                                        <div className={techHero.mainItemContentMobile}>
                                            <h3 className={techHero.mainItemTitleMobile}>
                                                {t('siteMetadata.technology2024.rec')}
                                            </h3>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.rec1')}
                                            </p>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.rec2')}
                                            </p>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.rec3')}
                                            </p>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.rec4')}
                                            </p>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.rec5')}
                                            </p>
                                            <p className={techHero.mainItemSubheaderMobile}>
                                                {t('siteMetadata.technology2024.recHeader')}
                                            </p>
                                            <p className={techHero.mainItemParagraphMobile}>
                                                {t('siteMetadata.technology2024.recSub')}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li id={techHero.secondImageWrapper}>
                                    <div className={techHero.mainItemMobile}>
                                        <div className={techHero.mainCardImageWrapperMobile}>
                                            <img src={workbenchMobile} alt='Workbench' />
                                        </div>
                                        <div className={techHero.mainItemContentMobile}>
                                            <h3 className={techHero.mainItemTitleMobile}>
                                                {t('siteMetadata.technology2024.workbench')}
                                            </h3>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.workbench1')}
                                            </p>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.workbench2')}
                                            </p>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.workbench3')}
                                            </p>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.workbench4')}
                                            </p>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.workbench5')}
                                            </p>
                                            <p className={techHero.mainItemSubheaderMobile}>
                                                {t('siteMetadata.technology2024.workbenchHeader')}
                                            </p>
                                            <p className={techHero.mainItemParagraphMobile}>
                                                {t('siteMetadata.technology2024.workbenchSub')}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li id={techHero.secondImageWrapper}>
                                    <div className={techHero.mainItemMobile}>
                                        <div className={techHero.mainCardImageWrapperMobile}>
                                            <img src={smartgateMobile} alt='Smartgate' />
                                        </div>
                                        <div className={techHero.mainItemContentMobile}>
                                            <h3 className={techHero.mainItemTitleMobile}>
                                                {t('siteMetadata.technology2024.smartgate')}
                                            </h3>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.smartgate1')}
                                            </p>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.smartgate2')}
                                            </p>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.smartgate3')}
                                            </p>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.smartgate4')}
                                            </p>
                                            <p className={techHero.mainItemListMobile}>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className={techHero.checkmark}
                                                />
                                                {t('siteMetadata.technology2024.smartgate5')}
                                            </p>
                                            <p className={techHero.mainItemSubheaderMobile}>
                                                {t('siteMetadata.technology2024.smartgateHeader')}
                                            </p>
                                            <p className={techHero.mainItemParagraphMobile}>
                                                {t('siteMetadata.technology2024.smartgateSub')}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </>
                    )
                    : (
                        <>
                            <div id={techHero.subsectionImageWrapper}>
                                <div className={techHero.subsectionImageTextWrapper}>
                                    <div className={techHero.mainImageContent}>
                                        <h1 className={techHero.mainImageTitleMake}>
                                            {t('siteMetadata.technology2024.simpler')}
                                        </h1>
                                        <p className={techHero.mainImageSub}>{t('siteMetadata.technology2024.simplerSub')}</p>
                                    </div>
                                </div>
                            </div>
                            {width < breakpointTablets
                                ? (
                                    <ul id={techHero.suiteListMobile}>
                                        <li id={techHero.secondImageWrapper}>
                                            <div className={techHero.mainItemMobile}>
                                                <div className={techHero.mainCardImageWrapperMobile}>
                                                    <img src={recMobile} alt='Remote Eye Controller' />
                                                </div>
                                                <div className={techHero.mainItemContentMobile}>
                                                    <h3 className={techHero.mainItemTitleMobile}>
                                                        {t('siteMetadata.technology2024.rec')}
                                                    </h3>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.rec1')}
                                                    </p>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.rec2')}
                                                    </p>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.rec3')}
                                                    </p>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.rec4')}
                                                    </p>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.rec5')}
                                                    </p>
                                                    <p className={techHero.mainItemSubheaderMobile}>
                                                        {t('siteMetadata.technology2024.recHeader')}
                                                    </p>
                                                    <p className={techHero.mainItemParagraphMobile}>
                                                        {t('siteMetadata.technology2024.recSub')}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li id={techHero.secondImageWrapper}>
                                            <div className={techHero.mainItemMobile}>
                                                <div className={techHero.mainCardImageWrapperMobile}>
                                                    <img src={workbenchMobile} alt='Workbench' />
                                                </div>
                                                <div className={techHero.mainItemContentMobile}>
                                                    <h3 className={techHero.mainItemTitleMobile}>
                                                        {t('siteMetadata.technology2024.workbench')}
                                                    </h3>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.workbench1')}
                                                    </p>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.workbench2')}
                                                    </p>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.workbench3')}
                                                    </p>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.workbench4')}
                                                    </p>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.workbench5')}
                                                    </p>
                                                    <p className={techHero.mainItemSubheaderMobile}>
                                                        {t('siteMetadata.technology2024.workbenchHeader')}
                                                    </p>
                                                    <p className={techHero.mainItemParagraphMobile}>
                                                        {t('siteMetadata.technology2024.workbenchSub')}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li id={techHero.secondImageWrapper}>
                                            <div className={techHero.mainItemMobile}>
                                                <div className={techHero.mainCardImageWrapperMobile}>
                                                    <img src={smartgateMobile} alt='Smartgate' />
                                                </div>
                                                <div className={techHero.mainItemContentMobile}>
                                                    <h3 className={techHero.mainItemTitleMobile}>
                                                        {t('siteMetadata.technology2024.smartgate')}
                                                    </h3>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.smartgate1')}
                                                    </p>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.smartgate2')}
                                                    </p>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.smartgate3')}
                                                    </p>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.smartgate4')}
                                                    </p>
                                                    <p className={techHero.mainItemListMobile}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.smartgate5')}
                                                    </p>
                                                    <p className={techHero.mainItemSubheaderMobile}>
                                                        {t('siteMetadata.technology2024.smartgateHeader')}
                                                    </p>
                                                    <p className={techHero.mainItemParagraphMobile}>
                                                        {t('siteMetadata.technology2024.smartgateSub')}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                )
                                : (
                                    <section id={techHero.threeColSection}>
                                        <ul id={techHero.threeColList}>
                                            <li className={techHero.item}>
                                                <LazyLoad offset={400} className={techHero.itemThumb}>
                                                    <img src={rec} className={techHero.itemIcon} alt='Remote Eye Controller' />
                                                </LazyLoad>
                                            </li>
                                            <div className={techHero.arrows}>
                                                <img src={arrows} className={techHero.arrowsIcon} alt='Arrows' />
                                            </div>
                                            <li className={techHero.item}>
                                                <LazyLoad offset={400} className={techHero.itemThumb}>
                                                    <img src={workbench} className={techHero.itemIcon} alt='Workbench' />
                                                </LazyLoad>
                                            </li>
                                            <div className={techHero.arrows}>
                                                <img src={arrows} className={techHero.arrowsIcon} alt='Arrows' />
                                            </div>
                                            <li className={techHero.item}>
                                                <LazyLoad offset={400} className={techHero.itemThumb}>
                                                    <img src={smartgate} className={techHero.itemIcon} alt='Smartgate' />
                                                </LazyLoad>
                                            </li>
                                        </ul>
                                        <ul id={techHero.threeColListMargin}>
                                            <li className={techHero.itemMargin}>
                                                <div className={techHero.itemText}>
                                                    <h3 className={techHero.itemTitle} dangerouslySetInnerHTML={{ __html: t('siteMetadata.technology2024.rec') }} />
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.rec1')}
                                                    </p>
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.rec2')}
                                                    </p>
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.rec3')}
                                                    </p>
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.rec4')}
                                                    </p>
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.rec5')}
                                                    </p>
                                                    <p className={techHero.itemSubtitle}>
                                                        {t('siteMetadata.technology2024.recHeader')}
                                                    </p>
                                                    <p className={techHero.itemParagraph} dangerouslySetInnerHTML={{ __html: t('siteMetadata.technology2024.recSub') }} />
                                                </div>
                                            </li>
                                            <li className={techHero.itemMargin}>
                                                <div className={techHero.itemText}>
                                                    <h3 className={techHero.itemTitle} dangerouslySetInnerHTML={{ __html: t('siteMetadata.technology2024.workbench') }} />
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.workbench1')}
                                                    </p>
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.workbench2')}
                                                    </p>
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.workbench3')}
                                                    </p>
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.workbench4')}
                                                    </p>
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.workbench5')}
                                                    </p>
                                                    <p className={techHero.itemSubtitle}>
                                                        {t('siteMetadata.technology2024.workbenchHeader')}
                                                    </p>
                                                    <p className={techHero.itemParagraph} dangerouslySetInnerHTML={{ __html: t('siteMetadata.technology2024.workbenchSub') }} />
                                                </div>
                                            </li>
                                            <li className={techHero.itemMargin}>
                                                <div className={techHero.itemText}>
                                                    <h3 className={techHero.itemTitle} dangerouslySetInnerHTML={{ __html: t('siteMetadata.technology2024.smartgate') }} />
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.smartgate1')}
                                                    </p>
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.smartgate2')}
                                                    </p>
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.smartgate3')}
                                                    </p>
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.smartgate4')}
                                                    </p>
                                                    <p className={techHero.itemList}>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={techHero.checkmark}
                                                        />
                                                        {t('siteMetadata.technology2024.smartgate5')}
                                                    </p>
                                                    <p className={techHero.itemSubtitle}>
                                                        {t('siteMetadata.technology2024.smartgateHeader')}
                                                    </p>
                                                    <p className={techHero.itemParagraph} dangerouslySetInnerHTML={{ __html: t('siteMetadata.technology2024.smartgateSub') }} />
                                                </div>
                                            </li>
                                        </ul>
                                    </section>
                                )}

                        </>
                    )}
            </div>
            {width < breakpointTablets
                ? null

                : (
                    <div id={techHero.blueImageWrapper}>
                        <div className={techHero.mainImageTextWrapper}>
                            <div className={techHero.blueImageContent}>
                                <h1 className={techHero.mainImageTitleBlue}>
                                    {t('siteMetadata.technology2023.blueTitle')}
                                </h1>
                                <p className={techHero.mainImageSub}>{t('siteMetadata.technology2023.blueSub')}</p>
                                <div className={techHero.learnMoreBuyButtons}>
                                    <div className={techHero.cardLearnMoreInlineContainerBlue}>
                                        <Link
                                            to='/articles/'
                                            className={techHero.cardLearnMoreInlineTabletsBlue}
                                        >
                                            {t('siteMetadata.technology2023.blueButton')}
                                        </Link>
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className={techHero.chevronRightWhite}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            {width < breakpointTablets
                ? (
                    <section id={techHero.quadColSectionMobile}>
                        <h2 id={techHero.quadColTitleMobile}>
                            {t('siteMetadata.technology2023.features')}
                        </h2>
                        <NocSection />
                        <ul id={techHero.quadColListMobile}>
                            <li className={techHero.itemMobile}>
                                <div className={techHero.mobileCardImageContainer}>
                                    <img src={scriptWriter} alt='Smart Navigate' />
                                </div>
                                <div className={techHero.itemContentMobile}>
                                    <h3 className={techHero.itemTitleMobile}>
                                        {t('siteMetadata.technology2023.smartNav')}
                                    </h3>
                                    <p className={techHero.itemParagraphMobile}>
                                        {t('siteMetadata.technology2023.smartNavSub')}
                                    </p>
                                </div>
                            </li>
                            <li className={techHero.itemMobile}>
                                <div className={techHero.mobileCardImageContainer}>
                                    <img src={smartgateDash} alt='Smartgate' />
                                </div>
                                <div className={techHero.itemContentMobile}>
                                    <h3 className={techHero.itemTitleMobile}>
                                        {t('siteMetadata.technology2023.smartgate')}
                                    </h3>
                                    <p className={techHero.itemParagraphMobile}>
                                        {t('siteMetadata.technology2023.smartgateSub')}
                                    </p>
                                </div>
                            </li>
                            <li className={techHero.itemMobile}>
                                <div className={techHero.mobileCardImageContainer}>
                                    <img src={rda} alt='Remote Device Access' />
                                </div>
                                <div className={techHero.itemContentMobile}>
                                    <h3 className={techHero.itemTitleMobile}>
                                        {t('siteMetadata.technology2023.rda')}
                                    </h3>
                                    <p className={techHero.itemParagraphMobile}>
                                        {t('siteMetadata.technology2023.rdaSub')}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </section>
                )
                : (
                    <section id={redesignStyles.doubleColSection}>
                        <h2 className={redesignStyles.doubleColSectionTitle}>
                            {t('siteMetadata.technology2023.features')}
                        </h2>
                        <NocSection />
                        <ul id={redesignStyles.doubleColSectionList}>
                            <div className={redesignStyles.borderRight}>
                                <li className={redesignStyles.itemFeature}>
                                    <div className={redesignStyles.itemText}>
                                        <h3 className={redesignStyles.itemTitle} dangerouslySetInnerHTML={{ __html: t('siteMetadata.technology2023.smartNav') }} />
                                        <p className={redesignStyles.itemParagraph} dangerouslySetInnerHTML={{ __html: t('siteMetadata.technology2023.smartNavSub') }} />
                                    </div>
                                    <LazyLoad offset={400} className={redesignStyles.itemThumb}>
                                        <img src={scriptWriter} className={redesignStyles.itemIcon} alt='Witbox Net' />
                                    </LazyLoad>

                                </li>
                            </div>
                            <ul className={redesignStyles.itemGrayDouble}>
                                <div className={redesignStyles.itemFeatureSub}>
                                    <div className={redesignStyles.itemText}>
                                        <h3 className={redesignStyles.itemTitle} dangerouslySetInnerHTML={{ __html: t('siteMetadata.technology2023.smartgate') }} />
                                        <p className={redesignStyles.itemParagraph} dangerouslySetInnerHTML={{ __html: t('siteMetadata.technology2023.smartgateSub') }} />
                                    </div>
                                    <LazyLoad offset={400} className={redesignStyles.itemThumb}>
                                        <img src={smartgateDash} className={redesignStyles.itemIcon} alt='Smartgate' />
                                    </LazyLoad>
                                </div>
                                <div className={redesignStyles.itemFeatureSub}>
                                    <div className={redesignStyles.itemText}>
                                        <h3 className={redesignStyles.itemTitle} dangerouslySetInnerHTML={{ __html: t('siteMetadata.technology2023.rda') }} />
                                        <p className={redesignStyles.itemParagraph} dangerouslySetInnerHTML={{ __html: t('siteMetadata.technology2023.rdaSub') }} />
                                    </div>
                                    <LazyLoad offset={400} className={redesignStyles.itemThumb}>
                                        <img src={rda} className={redesignStyles.itemIcon} alt='Witbox Plus' />
                                    </LazyLoad>
                                </div>
                            </ul>
                        </ul>
                    </section>
                )}
        </Layout>
    );
}
