import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

import styles from './styles/noc-section.module.scss';
import CheckIcon from '../ui/CheckIcon/CheckIcon';

const NocSection = () => (
    <div className={styles.nocSection}>
        <div className={styles.nocSection__imageTop}>
            <StaticImage
                src='../../images/technology/2024/NOC.webp'
                alt='NOC'
                placeholder='none'
            />
        </div>
        <h3 className={styles.nocSection__title}>Experience the power of a virtual NOC</h3>
        <div className={styles.nocSection__paragraph}>
            With our Remote Eye Controller, access and control all your test devices remotely from one web app - on your phone, tablet, or desktop!  Monitor your video services on real devices and networks, just like your end-users use every day (mobile, smart TVs, desktop, tablet, STBs).
            {' '}
            Easily record any video in the REC to collaborate with other teams and resolve issues detected in the field faster.
            {' '}
            With our virtual NOC, every team speaks the same language.
            {' '}
            <b>
                Deliver the best Quality of Experience to your viewers from one testing and monitoring platform.
            </b>
        </div>
        <div className={styles.nocSection__checklistContainer}>
            <div className={styles.nocSection__imageBottom}>
                <StaticImage
                    src='../../images/technology/2024/NOC.webp'
                    alt='NOC'
                    placeholder='none'
                />
            </div>
            <div className={styles.nocSection__checklist}>
                <span><b>Ready to go further?</b></span>
                <ul>
                    <li>
                        <CheckIcon />
                        Remotely activate test automation on your Witbox to proactively test your services and get alerts when errors are detected.
                    </li>
                    <li>
                        <CheckIcon />
                        The Witbox records video evidence for every test it performs, making it easy and fast to escalate issues.
                    </li>
                    <li>
                        <CheckIcon />
                        The REC also empowers engineers to manually confirm a successful fix once pushed in production.
                    </li>
                </ul>
                <Link to='/articles/new-virtual-noc-launch-press-release/' className={styles.nocSection__link}>
                    More details
                    <FontAwesomeIcon
                        icon={faChevronRight}
                    />
                </Link>
            </div>
        </div>
    </div>
);

export default NocSection;
